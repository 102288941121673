@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

/* Set the font-family and ensure it's applied correctly */
.title, .subtitle, h1, h2, h3, h4, h5, h6, p {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 600 !important; /* or any weight you need */
}

.home {
    background-color: #1e242f;
    position: absolute;
    width: 100vw;
    height: auto;
    margin: 0 auto;
    background-size: cover;
}

.home-container-text {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
    color: #FFFFFF;
    margin-bottom: 1rem;
    padding-bottom: 50px;
}

.bt-linear {
    background: linear-gradient(90deg, #5ea425 0%, #1e242f 109.89%);
    padding-left: 1rem;
}

.bt-red {
    background-color: #5ea425;
    text-size-adjust: 32px;
}

.logo {
    height: 35px;
    width: 180px;
    background-color: #5ea425;
    color: #1e242f;
    border-radius: 12px;
    font-family: sans serif;
    font-weight: 700;
    font-size: 26px;
}

@media (max-width: 48em) {
    .home {
        width: auto;
        height: auto;
    }
}

/* header */

.header {
    width: 100%;
    position: relative;
    padding-bottom: 2rem;
}

.header-container {
    width: 75%;
    min-height: 65vh;
    margin: 0 auto;
    padding-left: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-box {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media (max-width: 64em) {
    .header-container {
        width: 85%;
    }
}

@media (max-width: 48em) {
    .header-container {
        flex-direction: column-reverse;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    .header-box {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-items: left;
        margin-right: 150px;
    }
}

/* Mint section */

.mint-section {
    width: 100%;
    position: relative;
    color: white;
    padding-bottom: 4rem;
    text-align: center;
}

.mint-container {
    width: 55%;
    min-height: 80vh;
    margin-bottom: 1rem;
    margin: 0 auto;
    border: solid;
    border-color: #5ea425;
    border-width: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mint-section h1 {
    font-family: "Akaya Telivigala", cursive;
    font-size: 44px;
    font-weight: 600;
    text-transform: capitalize;
    margin: 1rem 0;
    text-align: center;
    padding-bottom: 1rem;
}

.mintnum {
    width: 200px;
    height: 40px;
    color: #000;
    font-size: 28px;
    padding: 2px;
    margin: 4px;
    font-weight: bold;
    border-radius: 50px;
}

.mint-container p {
    font-size: 1.4rem;
    font-family: var(--font-secondary);
    margin-bottom: 2rem;
    text-align: center;
    text-transform: capitalize;
}

@media (max-width: 64em) {
    .mint-container {
        width: 80%;
        min-height: 70vh;
    }
}

@media (max-width: 48em) {
    .mint-container {
        width: 80%;
        min-height: 75vh;
    }
}

/* Countdown component */

.countdown {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.countdown ul {
    display: flex;
    gap: 2rem
}

.countdown .clock-item {
    list-style: none;
    width: 70px;
    height: auto;
}

.countdown .clock-item .count-number {
    /* background: #f82167; */
    color: var(--font-color);
    font-size: 2rem;
    font-weight: 700;
    font-family: var(--font-secondary);
    padding: 1rem;
    display: inline-block;
    width: 100%;
    text-align: center;
    line-height: 1;
    border-radius: 4px 4px 0 0;
}

.countdown .clock-item .count-text {
    background: #5ea425;
    font-family: var(--font-secondary);
    font-size: 1.1rem;
    color: #fff;
    padding: 0.5rem 0.7rem;
    display: inline-block;
    width: 100%;
    text-align: center;
    line-height: 1;
    border-radius: 0 0 4px 4px;
}

@media (max-width: 64em) {
    .menu {
        gap: 1rem;
    }

    .countdown h1 {
        font-size: 2rem;
    }

    .countdown p {
        font-size: 1rem;
    }

    .clock-item {
        width: 40px;
    }
}

/* roadmap */

.roadmap {
    margin-top: 20px;
    margin-bottom: 4rem;
    color: white;
}

.roadmap h4 {
    font-size: 44px;
    font-weight: 600;
    font-family: "Akaya Telivigala", cursive;
    color: #fff;
    text-transform: capitalize;
}

.roadmap span {
    color: #5ea425;
    font-weight: bold;
    font-size: 26px;
    font-family: "Akaya Telivigala", cursive;
}

.roadmap p {
    border-top: 2px dashed;
    border-color: #5ea425 !important;
    margin: 0;
    padding: 30px;
}

.roadmap p:nth-child(even) {
    border-left: 2px dashed;
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;
    margin-right: 30px;
    padding-right: 0;
}

.roadmap p:nth-child(odd) {
    border-right: 2px dashed;
    border-bottom-right-radius: 30px;
    border-top-right-radius: 30px;
    margin-left: 30px;
    padding-left: 0;
}

.roadmap p:first-child {
    margin-top: 20px;
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.roadmap p:first-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

/* FAQ */

.faq {
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    padding-bottom: 50px;
    color: #fff;
}

.faqhead {
    font-family: "Akaya Telivigala", cursive;
    font-size: 44px;
    font-weight: 600;
    text-transform: capitalize;
    margin: 1rem 0;
    text-align: center;
    padding-bottom: 1rem;
    color: #fff;
}

.faq-questions {
    width: 60%;
    padding-top: 20px;
    padding-bottom: 30px;
    margin-left: 20%;
}

summary {
    font-size: 20px;
    background-color: rgba(255, 255, 255, 0);
    padding: 1rem;
    margin-bottom: 0px;
    outline: none;
    border-radius: 0.25rem;
    text-align: left;
    cursor: pointer;
    position: relative;
    padding-bottom: 10px;
}

.faq__content {
    font-family: 'Gilroy-Medium', sans-serif;
    margin-left: 15px;
    max-width: 400px;
}

details>summary::after {
    position: absolute;
    content: "+";
    right: 20px;
}

details[open]>summary::after {
    position: absolute;
    content: "-";
    right: 20px;
}

details>summary::-webkit-details-marker {
    display: none;
}

details>summary {
    list-style: none;
}

details>summary::-webkit-details-marker {
    display: none;
}

/* Footer */

.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 1px solid rgba(165, 165, 165, 0.1);
    padding-bottom: 1rem;
}

.footer-links {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    text-align: left;
}

.footer-links div {
    width: 250px;
    margin-bottom: 10px;
}

.footer-links_logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer-links_logo div {
    display: flex;
    align-items: center;
    position: relative;
}

.footer-links_logo div h3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
}


.footer-links_div {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.footer-links_div h4 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
    margin-bottom: 1rem;
}

.footer-links_div p {
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: #FFFFFF;
    margin: 0.5rem 0;
    cursor: pointer;
}

.footer-copyright {
    margin-top: 2rem;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: space-around;
    border-top: 1px solid rgba(165, 165, 165, 0.1);
    padding-top: 2rem;
}

.footer-copyright p {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
}

.footer-icon {
    margin-inline: 10px;
}

.dashboard {
    background-color: #1e242f;
    position: absolute;
    width: 100vw;
    height: auto;
    background-size: cover;
    padding-bottom: 50px;
}


.dashboard-section {
    width: 100%;
    position: relative;
    color: white;
    text-align: center;
}

.dashboard-container {
    width: 50%;
    margin: 0 auto;
    border: solid;
    border-color: #f82167;
    border-width: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dashboard-content {
    display: "grid";
    justify-content: center;
    align-items: center;
    text-align: left;
    padding-top: 4rem;
    padding-left: 20%;
    padding-bottom: 2rem;
    color: #fff;
}

.dashboard-section h1 {
    font-family: "Akaya Telivigala", cursive;
    font-size: 44px;
    font-weight: 600;
    text-transform: capitalize;
    margin: 1rem 0;
    text-align: center;
    padding: 50px;
}


.dashboard-container p {
    font-size: 1.4rem;
    margin-bottom: 2rem;
    text-align: center;
    text-transform: capitalize;
}

.dashboard-row {
    display: flex;
    width: 700px;
}

.dashboard-left {
    width: 400px;
}

.dashboard-left label {
    padding-bottom: 10px;
}

.dashboard-button {
    padding-top: 30px;
    padding-left: 10px;
}

.dashboard-button-up {
    padding-top: 0px;
    padding-left: 10px;
}

@media (max-width: 1200px) {
    .dashboard {
        width: 100vw;
        height: auto;
    }

    .dashboard-container {
        width: 65%;
        min-height: 75vh;
    }

    .dashboard-content {
        padding-left: 240px;
    }

    .dashboard-left {
        width: 280px;
    }

    .dashboard-button {
        padding-top: 35px;
        padding-left: 10px;
    }

    .dashboard-button-up {
        padding-top: 0px;
        padding-left: 10px;
    }

    .faq-questions {
        width: 100%;
        margin-left: 10%;
    }

}

@media (max-width: 964px) {
    .dashboard {
        width: auto;
        height: auto;
    }

    .dashboard-container {
        width: 65%;
        min-height: 75vh;
    }

    .dashboard-content {
        padding-left: 240px;
    }

    .dashboard-left {
        width: 280px;
    }

    .dashboard-button {
        padding-top: 35px;
        padding-left: 10px;
    }

    .dashboard-button-up {
        padding-top: 0px;
        padding-left: 10px;
    }

    .faq-questions {
        width: 80%;
        margin-left: 0%;
    }

    .footer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-top: 1px solid rgba(165, 165, 165, 0.1);
        padding-top: 1rem;
        width: 80%;
    }

    .faqhead {
        font-family: "Akaya Telivigala", cursive;
        font-size: 44px;
        font-weight: 600;
        text-transform: capitalize;
        margin: 1rem 0;
        text-align: center;
        padding-bottom: 1rem;
        width: 80%;
    }

    .roadmap {
        margin-top: 20px;
        margin-bottom: 4rem;
        color: white;
        width: 80%;
    }

    .roadmap h4 {
        font-size: 44px;
        font-weight: 600;
        font-family: "Akaya Telivigala", cursive;
        color: #fff;
        text-transform: capitalize;
        width: 80%;
    }
    
    .roadmap span {
        color: #5ea425;
        font-weight: bold;
        font-size: 26px;
        font-family: "Akaya Telivigala", cursive;
        width: 80%;

    }
    
    .roadmap p {
        border-top: 2px dashed;
        border-color: #5ea425 !important;
        margin: 0;
        padding: 30px;
        width: 80%;

    }
    
    .roadmap p:nth-child(even) {
        border-left: 2px dashed;
        border-bottom-left-radius: 30px;
        border-top-left-radius: 30px;
        margin-right: 30px;
        padding-right: 0;
        width: 60%;

    }
    
    .roadmap p:nth-child(odd) {
        border-right: 2px dashed;
        border-bottom-right-radius: 30px;
        border-top-right-radius: 30px;
        margin-left: 30px;
        padding-left: 0;
        width: 80%;

    }
    
    .roadmap p:first-child {
        margin-top: 20px;
        border-top: 0;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        width: 80%;
    }
    
    .roadmap p:first-child {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        width: 80%;
    }
}

@media (max-width: 768px) {
    .dashboard {
        width: auto;
        height: auto;
    }

    .dashboard-container {
        width: 65%;
        min-height: 75vh;
    }

    .dashboard-content {
        padding-left: 240px;
    }

    .dashboard-left {
        width: 280px;
    }

    .dashboard-button {
        padding-top: 35px;
        padding-left: 10px;
    }

    .dashboard-button-up {
        padding-top: 0px;
        padding-left: 10px;
    }

    .faq-questions {
        width: 70%;
        margin-left: 0%;
    }

    .faqhead {
        font-family: "Akaya Telivigala", cursive;
        font-size: 44px;
        font-weight: 600;
        text-transform: capitalize;
        margin: 1rem 0;
        text-align: center;
        padding-bottom: 1rem;
        width: 70%;
    }

    .footer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-top: 1px solid rgba(165, 165, 165, 0.1);
        padding-top: 1rem;
        width: 70%;
    }

    .roadmap {
        margin-top: 20px;
        margin-bottom: 4rem;
        color: white;
        width: 70%;
    }

    .roadmap h4 {
        font-size: 44px;
        font-weight: 600;
        font-family: "Akaya Telivigala", cursive;
        color: #fff;
        text-transform: capitalize;
        width: 70%;
    }
    
    .roadmap span {
        color: #5ea425;
        font-weight: bold;
        font-size: 26px;
        font-family: "Akaya Telivigala", cursive;
        width: 70%;

    }
    
    .roadmap p {
        border-top: 2px dashed;
        border-color: #5ea425 !important;
        margin: 0;
        padding: 30px;
        width: 70%;

    }
    
    .roadmap p:nth-child(even) {
        border-left: 2px dashed;
        border-bottom-left-radius: 30px;
        border-top-left-radius: 30px;
        margin-right: 30px;
        padding-right: 0;
        width: 70%;

    }
    
    .roadmap p:nth-child(odd) {
        border-right: 2px dashed;
        border-bottom-right-radius: 30px;
        border-top-right-radius: 30px;
        margin-left: 30px;
        padding-left: 0;
        width: 70%;

    }
    
    .roadmap p:first-child {
        margin-top: 20px;
        border-top: 0;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        width: 70%;
    }
    
    .roadmap p:first-child {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        width: 70%;
    }
    
}

@media (max-width: 570px) {

    .dashboard {
        width: 100vw;
        height: auto;
    }

    .dashboard-section h1 {
        font-size: 32px;
    }

    .dashboard-container {
        width: 50%;
        min-height: 70vh;
    }

    .dashboard-content {
        padding-left: 0;

    }

    .dashboard-row {
        flex-direction: column;
        width: 300px;
    }

    .dashboard-left {
        width: 300px;
    }

    .dashboard-button {
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .dashboard-button-up {
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .faq-questions {
        width: 60%;
        margin-left: 0%;
    }

    .faqhead {
        font-family: "Akaya Telivigala", cursive;
        font-size: 44px;
        font-weight: 600;
        text-transform: capitalize;
        margin: 1rem 0;
        text-align: center;
        padding-bottom: 1rem;
        width: 60%;
    }

    .footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 1px solid rgba(165, 165, 165, 0.1);
    padding-top: 1rem;
    width: 60%;
    }

    .roadmap {
        margin-top: 20px;
        margin-bottom: 4rem;
        color: white;
        width: 60%;
        text-align: left;
    }

    .roadmap h4 {
        font-size: 44px;
        font-weight: 600;
        font-family: "Akaya Telivigala", cursive;
        color: #fff;
        text-transform: capitalize;
        width: 60%;
        text-align: left;
    }
    
    .roadmap span {
        color: #5ea425;
        font-weight: bold;
        font-size: 26px;
        font-family: "Akaya Telivigala", cursive;
        width: 60%;
        text-align: left;

    }
    
    .roadmap p {
        border-top: 2px dashed;
        border-color: #5ea425 !important;
        margin: 0;
        padding: 30px;
        width: 60%;
        text-align: left;

    }
    
    .roadmap p:nth-child(even) {
        border-left: 2px dashed;
        border-bottom-left-radius: 30px;
        border-top-left-radius: 30px;
        margin-right: 30px;
        padding-right: 0;
        width: 60%;
        text-align: left;

    }
    
    .roadmap p:nth-child(odd) {
        border-right: 2px dashed;
        border-bottom-right-radius: 30px;
        border-top-right-radius: 30px;
        margin-left: 30px;
        padding-left: 0;
        width: 60%;
        text-align: left;

    }
    
    .roadmap p:first-child {
        margin-top: 20px;
        border-top: 0;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        width: 60%;
        text-align: left;
    }
    
    .roadmap p:first-child {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        width: 60%;
        text-align: left;
    }

    .mint-section {
        width: 70%;
        position: relative;
        color: white;
        padding-bottom: 4rem;
        text-align: left;
        padding-right: 20px;
    }
    
    .mint-container {
        width: 75%;
        min-height: 80vh;
        margin-bottom: 1rem;
        margin: 0 auto;
        border: solid;
        border-color: #5ea425;
        border-width: 10px;
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-items: left;
        
    }
    
    .mintnum {
        width: 200px;
        height: 40px;
        color: #000;
        font-size: 28px;
        padding: 2px;
        margin: 4px;
        font-weight: bold;
        border-radius: 50px;
    }

    .header {
        width: 65%;
        position: relative;
        padding-bottom: 2rem;
    }
    
    .header-container {
        width: 75%;
        min-height: 65vh;
        margin: 0 auto;
        padding-left: 5%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .header-box {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    
    .navbar-container {
        justify-content: flex-start !important; /* Align items to the start on mobile */
    }
    
    .navbar-brand img {
        margin-right: auto;
        margin-top: 30px;
        height: 160px; /* Smaller image on mobile */
        width: 70%; /* Maintain aspect ratio */
    }

    .navbar-collapse {
        justify-content: flex-start !important; /* Move account button to the left on mobile */
    }

    .modal {
        max-width: 70%;
    }

    


}
