/* Sale.css */
.navbar-brand-custom img {
    width: 100%;
    height: auto;
}

.footer-icon {
    color: white;
    font-size: 6rem; /* Domyślny rozmiar ikon */
}

@media (max-width: 768px) {
    .navbar-brand-custom img {
        height: 200px; /* Zmniejsz wysokość obrazu na urządzeniach mobilnych */
    }

    .footer-icon {
        font-size: 3rem; /* Zmniejsz rozmiar ikon na urządzeniach mobilnych */
    }

    .faqhead {
        font-size: 1.5rem; /* Zmniejsz rozmiar tekstu nagłówka na urządzeniach mobilnych */
    }
}